import {useClient} from '../../app/Ged';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { selectMetafieldsLabels, succeedMetafieldsLabelsFetch} from './clientFileSlice';
import useDocumentShare from "./useDocumentShare";

export default function useMetafieldsLabels() {
    const {request} = useClient();
    const metafieldsLabels = useSelector(selectMetafieldsLabels);
    const {documentShare} = useDocumentShare();
    const dispatch = useDispatch();

    useEffect(() => {
        if (metafieldsLabels) {
            return;
        }

        if (documentShare && documentShare.validation_template_id) {
            const fetchMetafieldsLabels = async () => {
                try {
                    const metafieldsReq = await request({
                        method: 'get',
                        url: `/api/v2/document_metafields/labels?document_share_guid=${documentShare.guid}`,
                    });
                    dispatch(succeedMetafieldsLabelsFetch(metafieldsReq.data));
                } catch (error) {
                    console.error('Error fetching metafields labels:', error);
                }
            };

            fetchMetafieldsLabels();
        }
    }, [dispatch, request, metafieldsLabels, documentShare]);

    return {metafieldsLabels};
}
